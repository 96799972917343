<template>
    <div class="handleDetails">
        <el-descriptions title="事件信息" :column="2" :labelStyle="{ wordBreak: 'keep-all' }" :colon="false">
            <el-descriptions-item label="风险类型：">{{ info.eventTypeString }}</el-descriptions-item>
            <el-descriptions-item label="发生时间：">{{ info.alertTime }}</el-descriptions-item>
            <el-descriptions-item label="详情地址：">{{ info.position }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions style="margin-top: 20px" title="老人信息" :column="2" :labelStyle="{ wordBreak: 'keep-all' }" :colon="false">
            <el-descriptions-item label="姓名：">{{ info.userName }}</el-descriptions-item>
            <el-descriptions-item label="联系方式：">{{ info.phone }}</el-descriptions-item>
        </el-descriptions>
        <div style="margin-top: 20px" class="title">紧急联系人</div>
        <el-table class="table" :data="tableData" border style="width: 80%">
            <el-table-column prop="urgentPeople" align="center" label="姓名" />
            <el-table-column prop="urgentPhone" align="center" label="联系方式" />
        </el-table>
        <el-descriptions v-if="!isFeedBack" style="margin-top: 20px" title="处理信息" :column="2" :labelStyle="{ wordBreak: 'keep-all' }" :colon="false">
            <el-descriptions-item label="处理状态：">
                <el-tag size="small" :type="info.status === 0 ? 'danger' : 'success'">
                    {{ ['未处理', '已处理', '安全'][info.status] }}
                </el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="处理时间：">{{ info.dealTime }}</el-descriptions-item>
            <el-descriptions-item labelClassName="tlxttt">
                <span class="smallTitle">处理时长</span>
                <el-tooltip class="item" effect="dark" content="该协助人员收到风险事件通知到提交处理信息的时长" placement="top-start">
                    <i class="el-icon-question"></i>
                </el-tooltip>
                <span>：</span>
                <span>{{ info.duration }}</span>
            </el-descriptions-item>
            <el-descriptions-item label="处理人员：">{{ info.dealName }}</el-descriptions-item>
            <el-descriptions-item label="处理人员电话：">{{ info.dealPhone }}</el-descriptions-item>
            <el-descriptions-item v-if="info.context" label="处理备注：">{{ info.context }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions v-else style="margin-top: 20px" title="反馈信息" :column="2" :labelStyle="{ wordBreak: 'keep-all' }" :colon="false">
            <el-descriptions-item label="发生原因：">
                {{ info.reason }}
            </el-descriptions-item>
            <el-descriptions-item label="反馈时间：">{{ info.dealTime }}</el-descriptions-item>
            <el-descriptions-item labelClassName="tlxttt">
                <span class="smallTitle">反馈时长</span>
                <el-tooltip class="item" effect="dark" content="已处理该风险事件到提交反馈信息的时长" placement="top-start">
                    <i class="el-icon-question"></i>
                </el-tooltip>
                <span>：</span>
                <span>{{ info.duration }}</span>
            </el-descriptions-item>
            <el-descriptions-item label="反馈人员：">{{ info.dealName }}</el-descriptions-item>
            <el-descriptions-item label="反馈人员电话：" :span="2">{{ info.dealPhone }}</el-descriptions-item>
            <el-descriptions-item label="反馈备注：">
                <div style="display: flex; flex-direction: column">
                    <span>{{ info.context }}</span>
                    <img v-if="info.url" style="width: 200px" :src="info.url" alt="" />
                </div>
            </el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
import { selectRiskLogFeedBackDetail } from '@/api/homeSave'
export default {
    // 处理详情
    name: 'HandleDetails',
    props: {
        currentItem: {
            type: Object,
            default: null
        },
        isFeedBack: {
            type: Boolean,
            default: false
        },
        riskId: null
    },
    data() {
        return {
            tableData: [],
            info: null
        }
    },
    mounted() {
        this.getInfo()
    },
    methods: {
        getInfo() {
            selectRiskLogFeedBackDetail({ riskId: this.riskId, dealLogId: this.currentItem.dealId }).then(res => {
                this.tableData = res.data.urgentConcatInfos
                this.info = res.data
                const userTypeStr = res.data.userType ? `(${['', '网格员', '网格长', '监督员', '值班员', '协助人员', '紧急联系人', '本人', '亲友'][res.data.userType]})` : ''
                this.info.dealName = this.info.dealName.replace('()', userTypeStr)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.handleDetails {
    padding: 20px;
    color: #333;
    font-size: 16px;
    .title {
        font-size: 16px;
        font-weight: bold;
    }
    ::v-deep .el-descriptions {
        font-size: 16px;
    }
    ::v-deep .el-descriptions__body {
        color: #333;
    }
    ::v-deep .tlxttt {
        display: none;
    }
}
</style>
